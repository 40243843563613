.footer {
  height: 45vh;
  background-color: rgb(0, 0, 0);
  text-align: center;
  /* margin-top:500px */
  transition: all 0.3s linear;
}

.sec-4 {
  position: relative;
  top: 50px;
}

.m {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  position: relative;
  top: 150%;
  left: 50%;
  transform: translate(-50%, 100%);
}

.m a {
  text-decoration: none;
}

.m a i {
  color: rgb(255, 255, 255);
}

.m a i:hover {
  /* color: rgb(255, 111, 224); */
}

.span {
  color: aliceblue !important;
  position: relative;
  top: 100px;
  font-size: 20px;
}
.m span {
  position: relative;
  cursor: pointer;
}
span ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color);
  background-image: var(--image);
  border-radius: 35%;
  width: 56px;
  height: 56px;
  z-index: 1;
  opacity: 0;
  transition: all 0.5s linear;
}



span:hover ::after {
  opacity: 1;
  top: -8px;
  left: -8px;
  transform: rotateZ(45deg);
}

/* span i {
  font-size:40px;
  border-radius: 16px;
  border: 1px solid #FFFFFF45;
  padding:10px;
  backdrop-filter: blur(16px) brightness(108%);
  background-color: rgba(240, 248, 255, 0.159);
  z-index: 2;
} */

span i {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  border-radius: 16px;
  border: 1px solid #FFFFFF45;
  padding:10px;
  backdrop-filter: blur(16px) brightness(108%);
  z-index:11;
}


span :hover i{
  /* color: black; */
  transition:all 0.2s linear;
  box-shadow:0px 8px 16px #2424241f;
  /* z-index:11; */
}



span:hover ::after {
  opacity: 1;
  top: -8px;
  left: -8px;
  transform: rotateZ(35deg);
  z-index:-11;
}
span:hover i {
  color: rgb(0, 0, 0);
  transition: all 0.2s linear;
  box-shadow: 0px 8px 16px O #2424241f;
  z-index:11;
}








@media (max-width:992px) {
  .footer {
    margin-top: 50px;
  }
}

@media (max-width:768px) {
  .footer {
    margin-top: 50px;
    height: 100vh;
    display: block;
  }

  .footer span {
    position: relative;
    top: 250px;
  }
}