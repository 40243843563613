@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@400;900&display=swap');

.faq {
    width: 100%;
    height: auto;
    margin-top: -50px;
    background-color: black;
    padding-bottom: 84px;
    padding-top: 10px;
}

.accordion-item {
    border: none;
    background: transparent !important;
    color: #ffb800;
}

button.accordion-button.collapsed {
    padding: 20px 35px !important;
    margin-top: 10px;
    /* border: 1px solid black; */
    /* background-color: rgb(252, 250, 248); */
    border-radius: 50px;
    /* background: #e0e0e0; */
    /* box-shadow: 20px 20px 60px #bebebe, */
    /* -20px -20px 60px #ffffff; */
    border-radius: 50px;
    /* background: #e0e0e0; */
    /* box-shadow: 20px 20px 60px #bebebe, */
    /* -20px -20px 60px #ffffff; */
    background: linear-gradient(262.31deg, hsla(0, 0%, 100%, .1) .27%, hsla(0, 0%, 100%, 0) 99.02%);
    border: 1px solid #404040;
    color: white;
    font-weight: 600;
    
}
.accordion-button:not(.collapsed) {
    background: linear-gradient(262.31deg,hsla(0,0%,100%,.1) .27%,hsla(0,0%,100%,0) 99.02%);
    border: 1px solid #404040;
    color: white;
}

button.accordion-button {
    padding: 20px 35px !important;
    margin-top: 10px;
    border-left: 4px solid rgb(253, 72, 244);
    font-size: 25px;
    /* background: rgba(255, 255, 255, 0.25) !important; */
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
    border-radius: 10px !important;
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
    border-radius: 50px;
    /* background: #e0e0e0; */
    /* box-shadow: 20px 20px 60px #bebebe, */
    /* -20px -20px 60px #ffffff; */
}

h2 {
    color: white;
    font-size: 50px !important;
    font-weight: 900;
    text-align: center;
    font-family:system-ui;
}
.h3,h3,h5{
    color:white;
}
.f1{
    color: #ffb800;
    margin-right: 10px;
}

@media (max-width:992px) {
    .faq {
        /* margin-top:700px; */
    }
}