.team{
    background:black;
}
.team h1{
    text-align: center;
    position: relative;
    top:-20px;
}
.team-pics{
    width:100%
}
.name {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-family: system-ui;
    color: white;
    margin-top: 10px;
}
.spans {
    font-size: 15px;
    color: #fea1e8;
}