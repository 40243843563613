@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@400;900&display=swap');

.roadmap {
  height:170vh;
  width: 100%;
  background: rgb(255, 248, 0);
  background:url("../assets/mesh\ \(8\).png");
  /* background: linear-gradient(
    -200deg,
    #f0a01f 20%,
    #F8CD09 70%
  ); */
  color: black!important;
  margin-top:100px;

}
.cross{
    position: relative;
    top:270px;
}

.desc{
  position: relative;
  top: 30px;
}

.roadmap h1{
    background-color: black!important;
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
}
.roadmap h3{
    font-weight:600;
    color:rgb(107, 60, 49);
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
}
.roadmap p{
  color: rgb(82 1 53);
    width:90%;
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
    word-spacing: 0.5rem; 
    font-size:1.3rem;
}
.mint-m{
  text-transform: uppercase;
  width: max-content;
  padding: 20px 27px;
  font-size: 18px;
  color: #fff;
  font-weight: 900;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  border-radius: 37px;
  background-color: #f58447;
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
}
.mint-m:hover{
  background-color:#ff35d2;;
}
@media (max-width:992px){
  .roadmap .row{
    display: block!important;
    width: 100%!important;
  }
  .roadmap .row .col-md-6{
    width:100%!important;
  }
  .roadmap{
    height:100%!important;
    padding-bottom:50px;
  }
  .desc{
    margin-left:50px;
  }
  .cross{
    position: relative;
    top:0px;
}
}
@media (max-width:768px){
  .roadmap .row{
    display: block!important;
    width: 100%!important;
  }
  .roadmap .row .col-md-6{
    width:100%!important;
  }
  .roadmap{
    height:100%!important;
    padding-bottom:50px;
  }
  .desc{
    margin-left:25px;
  }
.roadmap h1{
  font-size:40px;
  }
}